import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const [file, setFile] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setDownloadLink(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert("Por favor, selecione um arquivo.");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('https://backcsv.zap77.com/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setDownloadLink(`https://backcsv.zap77.com${response.data.zipFilePath}`);
        } catch (error) {
            console.error('Erro ao enviar o arquivo', error);
            alert('Erro ao processar o arquivo. Tente novamente.');
        }
    };

    return (
        <div className="container">
            <h1 className="text-center my-4">Formatar Contatos</h1>
            <div className="card p-4 shadow-sm">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="csvFile" className="form-label">Selecione um arquivo CSV ou XLSX</label>
                        <input
                            type="file"
                            id="csvFile"
                            className="form-control"
                            accept=".csv,.xlsx"
                            onChange={handleFileChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block mt-3">
                        Upload e Formatar
                    </button>
                </form>
                {downloadLink && (
                    <a href={downloadLink} download="ddds.zip" className="btn btn-success btn-sm btn-block mt-4">
                        Baixar Arquivo ZIP
                    </a>
                )}
            </div>
        </div>
    );
}

export default App;