// import React, { useState } from 'react';
// import axios from 'axios';

// function App() {
//     const [file, setFile] = useState(null);
//     const [downloadLink, setDownloadLink] = useState(null);

//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//         setDownloadLink(null); // Resetar o link de download quando um novo arquivo é selecionado
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         if (!file) {
//             alert("Por favor, selecione um arquivo CSV.");
//             return;
//         }

//         const formData = new FormData();
//         formData.append('csv', file);

//         try {
//             const response = await axios.post('http://localhost:5000/upload', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//                 responseType: 'blob', // Muito importante para manipular arquivos binários
//             });

//             const blob = new Blob([response.data], { type: 'text/csv' });
//             const downloadUrl = window.URL.createObjectURL(blob);
//             setDownloadLink(downloadUrl);
//         } catch (error) {
//             console.error('Erro ao enviar o arquivo', error);
//         }
//     };

//     return (
//         <div className="App">
//             <h1>CSV Phone Number Formatter</h1>
//             <form onSubmit={handleSubmit}>
//                 <input type="file" accept=".csv" onChange={handleFileChange} />
//                 <button type="submit">Upload and Format</button>
//             </form>
//             {downloadLink && (
//                 <a href={downloadLink} download="formatted_phone_numbers.csv" style={{ marginTop: '20px', display: 'block' }}>
//                     Download Formatted CSV
//                 </a>
//             )}
//         </div>
//     );
// }

// export default App;

// import React, { useState } from 'react';
// import axios from 'axios';

// function App() {
//     const [file, setFile] = useState(null);
//     const [downloadLink, setDownloadLink] = useState(null);

//     const handleFileChange = (event) => {
//         setFile(event.target.files[0]);
//         setDownloadLink(null); // Resetar o link de download quando um novo arquivo é selecionado
//     };

//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         if (!file) {
//             alert("Por favor, selecione um arquivo CSV.");
//             return;
//         }

//         const formData = new FormData();
//         formData.append('csv', file);

//         try {
//             const response = await axios.post('http://localhost:5000/upload', formData, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                 },
//             });

//             // Definir o link para baixar o arquivo ZIP
//             const zipFilePath = response.data.zipFilePath;
//             setDownloadLink(`http://localhost:5000/${zipFilePath}`);
//         } catch (error) {
//             console.error('Erro ao enviar o arquivo', error);
//         }
//     };

//     return (
//         <div className="App">
//             <h1>CSV Phone Number Formatter</h1>
//             <form onSubmit={handleSubmit}>
//                 <input type="file" accept=".csv" onChange={handleFileChange} />
//                 <button type="submit">Upload and Format</button>
//             </form>
//             {downloadLink && (
//                 <a href={downloadLink} download="ddds.zip" style={{ marginTop: '20px', display: 'block' }}>
//                     Download ZIP with CSVs
//                 </a>
//             )}
//         </div>
//     );
// }

// export default App;

import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    const [file, setFile] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setDownloadLink(null); // Resetar o link de download quando um novo arquivo é selecionado
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!file) {
            alert("Por favor, selecione um arquivo CSV.");
            return;
        }

        const formData = new FormData();
        formData.append('csv', file);

        try {
            const response = await axios.post('https://backcsv.zap77.com/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Definir o link para baixar o arquivo ZIP
            const zipFilePath = response.data.zipFilePath;
            setDownloadLink(`https://backcsv.zap77.com/${zipFilePath}`);
        } catch (error) {
            console.error('Erro ao enviar o arquivo', error);
        }
    };

    return (
        <div className="container">
            <h1 className="text-center my-4">Formatar Contatos CSV</h1>
            <div className="card p-4 shadow-sm">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="csvFile" className="form-label">Selecione um arquivo CSV</label>
                        <input
                            type="file"
                            id="csvFile"
                            className="form-control"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary btn-block mt-3">
                        Upload e Formatar
                    </button>
                </form>
                {downloadLink && (
                    <a
                        href={downloadLink}
                        download="ddds.zip"
                        className="btn btn-success btn-sm btn-block mt-4"
                    >
                        Baixar Arquivo ZIP
                    </a>
                )}
            </div>
        </div>
    );
}

export default App;

